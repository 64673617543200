@font-face {
  font-family: "Geogrotesque Wide";
  src: url("/public/GeogrotesqueSharpTRIAL-WideBd.otf");
  font-weight: 600;
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-geogrotesque-wide: "Geogrotesque Wide";

  /* font sizes */
  --font-size-11xl: 30px;
  --font-size-31xl: 50px;
  --font-size-21xl: 40px;

  /* Colors */
  --color-whitesmoke: #f2f2f2;
}
