body {
  overflow: hidden;
}
.steamuserimagesA1Icon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  object-fit: cover;
}
.rbLogo13Icon {
  width: 382px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  display: none;
  max-width: 100%;
}
.bonanza,
.rust {
  height: 65px;
  position: relative;
  font-weight: 600;
  display: inline-block;
  z-index: 3;
}
.bonanza {
  background: linear-gradient(#fca31e, #fca31e), #fff613;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bonanzaTitle,
.rustTextFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
}
.rustTextFrame {
  flex-direction: row;
  align-items: flex-end;
  padding: 46px 9px;
  box-sizing: border-box;
  background-image: url(/public/rb-logo1-3@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 382px;
  row-gap: 20px;
  max-width: 100%;
  z-index: 2;
}
.comingSoon1 {
  height: 39px;
  position: relative;
  font-size: var(--font-size-11xl);
  font-weight: 600;
  display: inline-block;
  z-index: 1;
  margin-top: -55px;
}
.comingSoon {
  width: 100%;
  position: relative;
  background: radial-gradient(
      50% 50%at 50% 50%,
      rgba(255, 219, 4, 0.03),
      transparent
    ),
    radial-gradient(50% 50%at 50% 50%, rgba(255, 4, 245, 0.03), transparent),
    linear-gradient(252.77deg, #000, #423c7a), linear-gradient(#141312, #141312),
    #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 304px 20px 394px;
  box-sizing: border-box;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-whitesmoke);
  font-family: var(--font-geogrotesque-wide);
}
@media screen and (max-width: 750px) {
  .bonanza,
  .rust {
    font-size: var(--font-size-21xl);
  }
  .comingSoon1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 450px) {
  .bonanza,
  .rust {
    font-size: var(--font-size-11xl);
  }
  .rustTextFrame {
    flex-wrap: wrap;
  }
  .comingSoon1 {
    font-size: 18px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rbLogo13Icon,
.rust,
.bonanza {
  animation: fadeIn 2s ease-in-out forwards;
}


